<template>
  <button
    @click.stop="$emit('click')"
    class="blackButton"
    :class="{
      'blackButton--fullwidth': fullwidth,
      'blackButton--frozen': frozen
    }">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'BlackButton', // A big, black CTA button used throughout kiuki,
  props: {
    fullwidth: {
      type: Boolean,
      required: false,
      default: false
    },
    frozen: { // Stops hover effects
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style scoped lang="stylus">
.blackButton
  position relative
  display inline-flex
  margin 10px 0
  align-items center
  justify-content center
  font inherit
  font-size 1rem
  padding 6px 26px
  background-color black
  color white
  border-radius 6px
  border none
  cursor pointer
  transition background-color .2s, opacity .08s
  i
    margin-left 10px
  &:hover
    background-color #393939
  &:active
    opacity .8
  &--fullwidth
    width 100%
  &--frozen
    background black !important
    opacity 1 !important
  .blackButton + &:last-of-type
    margin-top 0
  &[disabled]
    background-color #666
    color #aaa
    cursor default
</style>
